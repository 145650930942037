@import 'BioSite/styles/template/common/common';

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 5.8rem;
  font-size: 1.4rem;
  font-weight: 500;
  color: var(--biositeColorProfile-buttonTextText);
  background-color: var(--biositeColorProfile-buttonText);
  border-radius: 0.6rem;

  &[disabled] {
    color: var(--biositeColorProfile-buttonTextText);
    cursor: pointer;
    background: var(--biositeColorProfile-buttonTextDim);
    border: none;
  }
}
