@overlay-opacity: 0.8;
@animation-duration: 400ms;
@overlay-background: rgba(0, 0, 0, 0.2);
@overlay-backdrop-filter: blur(10px);

.enterMobile {
  .overlay {
    opacity: 0;
  }

  .modal {
    opacity: 0;
    transform: translateY(100%);
  }
}

.enterActiveMobile {
  .overlay {
    opacity: 1;
    transition: opacity @animation-duration;
  }

  .modal {
    opacity: 1;
    transition:
      opacity @animation-duration,
      transform @animation-duration;
    transform: translateY(0);
  }
}

.exitMobile {
  .overlay {
    opacity: 1;
  }

  .modal {
    opacity: 1;
    transform: translateY(0);
  }
}

.exitActiveMobile {
  .overlay {
    opacity: 0;
    transition: opacity @animation-duration;
  }

  .modal {
    opacity: 0;
    transition:
      opacity @animation-duration,
      transform @animation-duration;
    transform: translateY(100%);
  }
}
