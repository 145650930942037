.inputContainer {
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  height: 40px;
  margin-top: 24px;
  border: 1px solid var(--biositeColorProfile-buttonTextDim);
  border-radius: 6px;
  outline: none;

  .iconContainer {
    position: absolute;
    display: flex;
    align-items: center;
    width: 16px;
    height: 21px;
    padding-left: 12px;

    svg {
      path {
        fill: var(--biositeColorProfile-buttonTextDim);
      }
    }

    &.nameIcon {
      svg {
        path {
          stroke: var(--biositeColorProfile-buttonTextDim);
        }
      }
    }
  }

  &:focus-within,
  &.hasInput {
    border: 1px solid var(--biositeColorProfile-buttonText);

    svg {
      path {
        fill: var(--biositeColorProfile-buttonText);
      }
    }

    &.nameIcon {
      svg {
        path {
          stroke: var(--biositeColorProfile-buttonText);
        }
      }
    }

    &:not(:focus-within) {
      border: 1px solid var(--biositeColorProfile-buttonTextDim);
    }
  }
}

.textInput {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding-left: 40px;
  font-size: 12px;
  color: var(--biositeColorProfile-buttonText);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: unset;
  border: none;
  border-radius: inherit;
  outline: none;
  box-shadow: none;

  &::placeholder {
    -webkit-text-fill-color: var(--biositeColorProfile-buttonTextDim);
    color: var(--biositeColorProfile-buttonTextDim);
  }
}

.lineBreak {
  flex-basis: 100%;
  height: 0;
}

.error {
  display: flex;
  margin-top: 8px;
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
  color: #ff7c7c;
}
