.variant6_container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0 20px;

  .donutCircle {
    position: relative;
    width: 24px;
    height: 18px;

    svg {
      display: block;
      width: 24px;
      height: 20px;
    }
  }

  .textContainer {
    @keyframes moveUpAndDown {
      0% {
        transform: translateY(0);
      }

      45% {
        transform: translateY(0);
      }

      50% {
        transform: translateY(-100%);
      }

      95% {
        transform: translateY(-100%);
      }

      100% {
        transform: translateY(0);
      }
    }

    height: 12px;
    padding-right: 10px;
    overflow: hidden;
    color: var(--biositeColorProfile-text);

    span {
      display: block;
      animation: moveUpAndDown 9s ease-out infinite;
    }
  }

  .arrow {
    padding-top: 3px;
  }
}
