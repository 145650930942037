@import (reference) '../mixins.less';

@linkGap: 1.2rem;
@baseStackIndex: 15;
// 5rem to ensure the shadow isn't cut
@shadowSafetyMargin: 5rem;
@transition-duration: 0.3s;

.dummyWrapper {
  position: relative;
}

.advanced-05 {
  .advanced-link();
  position: relative;
  flex-direction: row;
  gap: 1.2rem;
  align-items: start;
  max-width: 48.6rem;
  min-height: 8rem;
  padding: 1.2rem;
  margin-top: 0;
  margin-bottom: 0;
  clip-path: polygon(
    -@shadowSafetyMargin -@shadowSafetyMargin,
    calc(100% + @shadowSafetyMargin) -@shadowSafetyMargin,
    calc(100% + @shadowSafetyMargin) calc(100% + @shadowSafetyMargin),
    -@shadowSafetyMargin calc(100% + @shadowSafetyMargin)
  );
  background: var(--biositeColorProfile-buttonBackground);
  backdrop-filter: blur(0.8rem);
  border-radius: 1.6rem;
  opacity: 1;
  transform-origin: top center;

  @supports not (backdrop-filter: blur(0.8rem)) {
    background: var(--biositeColorProfile-buttonBackgroundSolid);
  }

  &:not(.hasThumbnail) {
    padding-left: 1.6rem;
  }

  &.canAnimate {
    transition: all @transition-duration ease-in-out !important;
  }

  &:not(&.canAnimate) {
    transition-delay: 0s !important;
  }

  &.first {
    // The first one is always on top
    z-index: @baseStackIndex;
    padding-right: 6rem;
  }

  // Until the 10th element, control its z-index and scale (no need for more than 10
  // because at that time the scale is already zero)
  each(range(2, 10), {
    &:not(.first):nth-child(@{value}) {
      @scaleFactor: (@index / 10);
      @delayFactor: (@index / 20);

      z-index: (@baseStackIndex - @value);

      &.isCollapsed {
        pointer-events: none;
      }

      &.isCollapsed when (@index < 3) {
        transform: scaleX((1 - @scaleFactor));
      }

      &.isCollapsed when (@index >= 3) {
        transform: scale((1 - @scaleFactor));
      }

      &:not(&.isCollapsed) {
        transition-delay: ~"@{delayFactor}s";
      }
    }
  });

  &.isCollapsed {
    &:not(.first) {
      // Hack:
      // Prevent the content from overflowing only in the top (for example, if the second link is
      // taller that the first, when it gets collapsed/behind the first, we still see it above)
      // With overflow it doesn't work because it cuts the shadows

      // 8rem of visible area
      @visibleLinkArea: 8rem;

      // Following clockwise order:
      // 1 ----- 2
      // |       |
      // 4 ----- 3
      clip-path: polygon(
        -@shadowSafetyMargin calc(100% - @visibleLinkArea),
        calc(100% + @shadowSafetyMargin) calc(100% - @visibleLinkArea),
        calc(100% + @shadowSafetyMargin) calc(100% + @shadowSafetyMargin),
        -@shadowSafetyMargin calc(100% + @shadowSafetyMargin)
      );
    }

    &:nth-child(3) {
      & ~ .advanced-05 {
        // Hide from the 3rd element onwards because they are not stacked, therefore not visible
        opacity: 0;
      }
    }

    &:nth-child(11) {
      & ~ .advanced-05 {
        // Keep the remaining elements in the same state
        z-index: 5;
        transform: scale(0);
      }
    }
  }

  &.isExpanded {
    &.first,
    &:not(:last-child) {
      // Apply the spacing between links when they are expanded
      margin-bottom: @linkGap;
    }
  }

  .thumbnail {
    box-sizing: border-box;
    flex-shrink: 0;
    width: 5.6rem;
    height: 5.6rem;
    object-fit: cover;
    border-radius: 0.4rem;

    &.icon {
      border: 1px solid var(--biositeColorProfile-buttonTextDim);
    }
  }

  .name {
    flex-grow: 1;
    align-self: center;
    font-size: 14px;
    line-height: 1.28;
  }
}

.expandButton {
  position: absolute;
  top: 0;
  right: 0;
  z-index: (@baseStackIndex + 1);
  width: 6rem;
  height: 100%;
  color: var(--biositeColorProfile-buttonTextDim);
  cursor: pointer;
  background: transparent;
  border: 0;

  &:active {
    & + .advanced-05 {
      transform: scale(0.98);
    }

    svg {
      transform: scaleY(1) translateX(-0.4rem);
    }
  }

  &.isExpanded {
    &:active {
      & + .advanced-05 {
        transform: scale(1.02);
      }

      svg {
        transform: scaleY(-1) translateX(0.4rem);
      }
    }

    svg {
      transform: scaleY(-1);
    }
  }

  &.canAnimate {
    svg {
      transition: transform @transition-duration ease-in-out;
    }
  }

  svg {
    flex-shrink: 0;
    transform-origin: center 1.1rem;
  }
}
