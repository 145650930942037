@import 'colors.less';
@import 'mixins.less';
@import 'BioSite/styles/vars';

@duration-short: 200ms;

@font-face {
  font-family: 'Clarkson';
  font-style: normal;
  font-weight: 500;
  src: data-uri('public/template/common/clarkson-500.woff') format('woff');
}

@font-face {
  font-family: 'Clarkson';
  font-style: normal;
  font-weight: 400;
  src: data-uri('public/template/common/clarkson-400.woff') format('woff');
}

@media (prefers-reduced-motion: reduce) {
  * {
    transition: none !important;
    animation: none !important;
    scroll-behavior: auto !important;
  }
}

.noAnimate {
  *,
  *::before,
  *::after {
    transition: none !important;
    animation: none !important;
    scroll-behavior: auto !important;
  }
}

html {
  font-size: 10px;
}

html,
body {
  height: 100%;
  padding: 0;
  margin: 0;
}

a {
  text-decoration: none;
}

img {
  overflow: hidden;
  text-indent: 100%;
  white-space: nowrap;
}

.skip-link {
  position: absolute;
  top: -50px;
  left: 0;
  z-index: 100;
  padding: 8px;
  color: white;
  background: #000000;

  &:focus {
    top: 0;
  }
}

.clickable-element {
  iframe {
    pointer-events: none;
  }
}

#videoBackground {
  position: absolute;
  top: 0;
  z-index: 0;
  width: 100%;
  height: 100%;

  img {
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
  }

  video {
    position: fixed;
    top: 0;
    left: 0;
    object-fit: cover;
  }
}

#app {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  font-family: var(--secondary-font, 'Lato'), serif;
  font-weight: var(--secondary-font-weight, 400);
  /* stylelint-disable-next-line property-no-unknown */
  font-smooth: always;
  color: var(--biositeColorProfile-text);
  background: var(--biositeColorProfile-background);
  background-attachment: local;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: auto;

  #ellaCurve {
    display: none;
  }

  #main {
    text-align: center;

    .main-content {
      z-index: 10001;

      .name-bio-wrapper {
        .name {
          font-family: var(--primary-font);
          font-weight: var(--primary-font-weight);
        }

        .bio {
          width: 100%;
          max-width: @sectionMaxWidth;
          margin: 0 auto;

          .content {
            padding: 0 12px;
          }
        }
      }
    }
  }
}

section {
  @keyframes intro-animation {
    0% {
      top: 60px;
      transform: scale(0) translateZ(0);
    }

    100% {
      top: 0;
      transform: scale(1) translateZ(0);
    }
  }

  max-width: @sectionMaxWidth;
  padding: @sectionVerticalPadding 22px;
  margin: 0 auto;

  --animation-timing: calc(var(--animation-delay) / 5);
  animation: intro-animation calc(var(--animation-timing) + 0.2s) backwards;
}

body footer.watermark {
  padding: 28px 0 16px;
  font-family: 'Clarkson', sans-serif;
  text-transform: uppercase;

  a {
    display: inline-block;
    font-size: 9px;
    line-height: 11px;
    color: rgba(0, 0, 0, 0.2);
    letter-spacing: 0.5px;

    span.unfold {
      display: block;
      font-size: 11px;
      line-height: 20px;
      color: #000;
      letter-spacing: 0.5px;
    }
  }
}

#main.hasSignupBanner {
  padding-bottom: 125px;
}

body .cta_footer {
  display: flex;
  justify-content: center;
}

#signup_banner {
  position: relative;
  z-index: 100;
  display: flex;
  justify-content: center;
  width: 100%;
  max-width: 100vw;
  margin: 40px 0 60px;
  text-transform: uppercase;
}
