@import (reference) '../mixins.less';

.advanced-01 {
  .advanced-link();
  max-width: 486px;
  min-height: 652px;
  padding: 22px;
  padding-bottom: 32px;
  border-radius: 24px;
  opacity: 0;
  transition:
    opacity 0.3s ease-out,
    transform 0.3s ease-out;
  transform: scale(0.9) translateY(50px);

  &:not(:last-child) {
    margin-bottom: 16px;
  }

  &:not(.canAnimate) {
    transition-duration: 0s;
  }

  &.isVisible {
    opacity: 1;
    transform: scale(1) translateY(0);
  }

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .counter {
      font-size: 14px;
      line-height: 2.2;
      color: currentColor;
    }
  }

  .thumbnailWrapper {
    display: flex;
    flex-grow: 1;
    align-items: center;
    justify-content: center;
    margin-top: 24px;
    margin-bottom: 24px;

    .thumbnailPlaceholder {
      font-size: 120px;
      color: var(--biositeColorProfile-buttonTextDim);
      text-align: center;
    }

    .thumbnail {
      width: 318px;
      max-width: 100%;
      aspect-ratio: 1;
      object-fit: cover;
      border-radius: 50%;

      &.isIcon {
        border: 1px solid var(--biositeColorProfile-buttonTextDim);
      }
    }
  }

  .name {
    font-size: 24px;
    line-height: 1.28;
  }

  .url {
    margin-top: 10px;
    overflow: hidden;
    font-size: 14px;
    line-height: 1;
    color: var(--biositeColorProfile-buttonTextDim);
    text-overflow: ellipsis;
    text-transform: uppercase;
    white-space: nowrap;
  }
}
